<template lang="pug">
modal.auth-modal(v-show="mounted", ref="modal", transition="modal", :scrollable="true", :max-width="504", :name="modalName", :adaptive="true", height="auto", @closed="enableScrolling", @before-close="resetModalState")
  .modal-header
    .title {{ $t('auth.allSites.modal.title') }}
    .close-button.is-flex(@click="onCloseHandler")
      icon(iconName="close", iconColor="#2e2e2e")
  .modal-body
    .tabs.is-flex.is-flex-direction-column
      .tabs__header.is-flex
        div.tab(:class="{ active: activeTab === 'signIn' }" @click="activeTab = 'signIn'") {{$t('auth.allSites.modal.signIn') }}
        div.tab(:class="{ active: activeTab === 'register' }" @click="activeTab = 'register'") {{$t('auth.allSites.modal.register')}}
      .tabs__info
        p(v-html="localizedInfoText" @click="handlerClickInfoText")
        .tabs__wrapper(v-if="viewLogo")
          .tabs__image.is-flex(v-for="site in logoSites", :key="site.key")
            v-link(:to="site.url", type="native")
              v-image(:src="site.src", :alt="`logo site ${site.name}`")
      .modal-body__tab-content
        .sign-in-tab(v-if="activeTab === 'signIn'")
          sign-in-form(@close-modal="onCloseHandler")
        .register-tab(v-if="activeTab === 'register'")
          sign-up-form
</template>

<script>
import modalMixin from '~~/mixins/modalMixin'
import { companyName } from '~~/utils/definitions/defaults'

const siteName = process.env.siteName
const optionSites = {
  ruby: {
    key: 'f73e1ccb-91db-4c82-a8b5-2d12bb1a62ee',
    url: 'https://thenaturalrubycompany.com/',
    src: 'logos/allSites/ruby@2x.jpg'
  },
  emerald: {
    key: '448bdb93-7bf7-4294-8c19-bab5a2390c45',
    url: 'https://www.emeralds.com/',
    src: 'logos/allSites/emerald@2x.jpg'
  },
  gemstone: {
    key: '8f61b9a3-36ec-4b27-811c-f6b35ca5c7da',
    url: 'https://www.naturalgemstones.com/',
    src: 'logos/allSites/gemstone@2x.jpg'
  },
  sapphire: {
    key: '8f61b9a3-36ec-4b27-811c-f6b35ca5c7df',
    url: 'https://www.naturalgemstones.com/',
    src: 'logos/allSites/gemstone@2x.jpg'
  }
}

export default {
  name: 'AuthModal',

  components: {
    SignInForm: () => import('./SignInForm.vue'),
    SignUpForm: () => import('./SignUpForm.vue')
  },

  mixins: [modalMixin],

  data: () => ({
    modalEvent: 'auth-modal',
    modalName: 'auth-modal',
    activeTab: 'signIn',
    viewLogo: false
  }),

  computed: {
    localizedInfoText() {
      return this.$t(`auth.allSites.modal.infoText.${this.activeTab}`, {
        companyName: this.companyNameSite
      })
    },

    logoSites() {
      return this.getSitesExcludingCurrent(siteName)
    },

    companyNameSite() {
      return companyName[process.env.siteName]
    }
  },

  beforeDestroy() {
    this.$root.$off('login-complete', this.onCloseHandler)
    this.$root.$off('register-complete', this.onCloseHandler)
  },

  mounted() {
    this.$root.$on('login-complete', this.onCloseHandler)
    this.$root.$on('register-complete', this.onCloseHandler)
    this.$root.$on('routeChanged', this.onCloseHandler)
  },

  methods: {
    handlerClickInfoText(e) {
      if (e.target.id === 'our-sites') {
        this.viewLogo = !this.viewLogo
      }
      if (e.target.id === 'sign-in-form') {
        this.activeTab = 'signIn'
      }
    },

    resetModalState() {
      this.activeTab = 'signIn'
      this.viewLogo = false
    },

    getSitesExcludingCurrent(currentSiteName) {
      const filteredSites = Object.keys(optionSites).filter(
        (site) => site !== currentSiteName
      )
      return filteredSites.map((site) => ({
        key: optionSites[site].key,
        url: optionSites[site].url,
        name: site,
        src: optionSites[site].src
      }))
    }
  }
}
</script>

<style scoped lang="sass">
.auth-modal
  ::v-deep
    .v--modal-box
      padding: 20px
      position: relative
  .modal-header
    .title
      font-family: $lora
      font-style: italic
      font-size: 30px
      line-height: 39px
      text-align: left
  .close-button
    position: absolute
    top: 15px
    right: 15px
  .tabs
    &__info
      margin-top: 10px
      background: #F8F7F3
      white-space: normal
      //margin-bottom: 10px
      padding: 20px 16px
      p
        font-size: 16px
        line-height: 24px
        ::v-deep
          span
            cursor: pointer
            font-weight: bold
            text-decoration: underline
    &__wrapper
      margin-top: 16px
      display: flex
      flex-direction: column
      gap: 20px
    &__image
      a
        padding: 0
    &__header
      border-bottom: 1px solid #ccc
      .tab
        font-size: 20px
        line-height: 24px
        font-weight: bold
        cursor: pointer
        padding: 10px 20px
        width: 50%
        text-align: center
        &:not(.active)
          color: #888
        &.active
          border-bottom: 4px solid $default-font-color

  .modal-body__tab-content
    margin-top: 6px
    ::v-deep
      h4
        display: none
      a
        padding: unset
        justify-content: unset
      .submit-controls
        flex-direction: column
      .submit-control
        margin-left: unset !important
        button
          width: 100% !important
      .control
        .icon
          margin-left: unset
</style>
